<template>
    <div class="main-container">
        <el-config-provider :locale="currentLocale">
            <RouterView />
        </el-config-provider>
    </div>
</template>

<script setup lang="ts">
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import { useLangStore } from '@/store/i18n'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const { t } = useI18n()

router.beforeEach((to) => {
    if (to.meta.title) {
        to.meta.title = t(to.meta.title as string) // 设置页面标题
    }
})
const langStore = useLangStore()
const currentLocale = ref(langStore.language === 'zh' ? zhCn : en)
watch(langStore, (newNmuber, _oldVal) => {
    currentLocale.value = newNmuber.language === 'zh' ? zhCn : en
})
</script>
<style scoped>
.main-container {
    /* display: flex; */
    height: 100%;
    width: 100%;
    /* justify-content: space-between; */
}
</style>
