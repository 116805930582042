import './style.css'
import './style/_style.scss'
import './assets/fonticon/iconfont.css'
import './assets/font/font.css'

import { createApp } from 'vue'

import App from './App.vue'
// import i18n from './assets/locale';
import { setupElementPlus, setupI18n, setupPinia, setupRouter } from './plugins'

// 创建 Pinia 实例
const app = createApp(App)

setupI18n(app)

setupPinia(app)

setupRouter(app)

setupElementPlus(app)

app.mount('#app')
