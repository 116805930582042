import { createPinia } from 'pinia'
import { App } from 'vue'
import PiniaPersist from 'pinia-plugin-persistedstate'
function setupPinia(app: App<Element>) {
    const pinia = createPinia()
    pinia.use(PiniaPersist)

    app.use(pinia)
}

export default setupPinia
