import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type LangType = 'en' | 'zh'
const langs = ['en', 'zh']

export const useLangStore = defineStore('lang', () => {
    const language = ref<LangType>('zh')

    const { locale } = useI18n()

    function setLang(lang: LangType) {
        if (langs.includes(lang)) {
            locale.value = lang

            language.value = lang
        } else {
            console.warn('未找到对应的语言,使用缺省语言')
        }
    }

    return {
        language,
        setLang,
        locale,
    }
})
