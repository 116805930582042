import {
  computed,
  ref,
} from 'vue';

import _ from 'lodash';
import { defineStore } from 'pinia';

import { useDayjs } from '@/hooks/useDayjs';
import { UnwrapRes } from '@/types/response';
import { UserApi } from '@/utils/api/modules';

type IOrder = UnwrapRes<typeof UserApi.orderInUse>['last_used_package']

const useUserStore = defineStore(
    'userStore',
    () => {
        const isLogin = ref(false)

        const userInfo = ref({
            username: '',
        })
        const superAdmin = ref(false)
        const admin = ref(false)
        const admin_permission = ref(false)

        const token = ref('')

        const isAdmin = computed(() => {
            return superAdmin.value || admin_permission
        })

        const systemVersion = ref<0 | 1>(0)

        const isEnterprise = computed(() => {
            return systemVersion.value === 1
        })

        const order = ref<IOrder>()
        const { dayjs } = useDayjs()

        const hasSubscribe = computed(() => {
            if (_.isArray(order.value)) {
                return false
            }

            if (!order.value) return false

            if(!order.value.expire_time) return false

            const isExpire = dayjs(order.value.expire_time).isBefore(
                dayjs(new Date()),
            )

            if (isExpire) return false

            return true
        })

        const isUser = computed(() => {
            return (
                superAdmin.value === false &&
                admin_permission.value === false &&
                admin.value === false
            )
        })

        return {
            isLogin,
            userInfo,
            token,
            superAdmin,
            admin,
            admin_permission,
            isAdmin,
            systemVersion,
            isEnterprise,
            order,
            isUser,
            hasSubscribe,
        }
    },
    {
        // 启动持久化
        persist: true,
    },
)

export { useUserStore };
