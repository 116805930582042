import type { App } from 'vue'

import { initRouter } from '@/router/router'

function setupRouter(app: App<Element>) {
    const router = initRouter()

    app.use(router)
}

export default setupRouter
