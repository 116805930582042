import 'dayjs/locale/zh-cn';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

export const useDayjs = () => {
    dayjs.locale('zh-cn')
    dayjs.extend(duration)
    dayjs.extend(relativeTime)

    const getHumanize = (
        time: number,
        unit: duration.DurationUnitType = 'second',
    ) => {
        const dura = dayjs.duration(time, unit)
        const days = dura.days()
        const hours = dura.hours()
        const minutes = dura.minutes()

        if (days > 0) {
            return `${days}天${hours}小时${minutes}分`
        }

        if (hours > 0) {
            return `${hours}小时${minutes}分`
        }

        return `${minutes}分`
    }

    

    return {
        getHumanize,
        dayjs,
    }
}
