import { defineStore } from 'pinia'
import { ref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'

const useMenuStore = defineStore(
    'menuStore',
    () => {
        const pathList = ref<RouteLocationNormalized[]>([])

        function jumpTo(
            to: RouteLocationNormalized,
            from: RouteLocationNormalized,
        ) {
            if (to.meta && Object.hasOwn(to.meta, 'level')) {
                const toLevel = to.meta.level || 0
                const fromLevel = from.meta.level || 0
                if (toLevel < 0) {
                } else if (toLevel == 0) {
                    pathList.value = [to]
                } else if (toLevel > fromLevel) {
                    const idx = pathList.value.findIndex(
                        (item) => item.meta.level === toLevel,
                    )

                    if (idx > -1) {
                        pathList.value.splice(idx)
                    }

                    pathList.value.push({ ...to })

                    console.log('after push pathList.value======>', pathList.value)
                } else {
                    const idx = pathList.value.findIndex((item, index) => {
                        if (item.meta.level === toLevel) {
                            return true
                        } else {
                            return false
                        }
                    })

                    pathList.value.splice(idx)
                    pathList.value.push({ ...to })

                    console.log('after push pathList.value======>', pathList.value)
                }
            }

            return
        }

        const currentPath = ref<RouteLocationNormalized | null>(null)

        return {
            pathList,
            jumpTo,
            currentPath,
        }
    },
    {
        persist: {
            storage: sessionStorage,
         }
    },
)

export { useMenuStore }
