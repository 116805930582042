import { App } from 'vue'

import { createI18n } from 'vue-i18n'

import en from '@/locale/en.json'
import zh from '@/locale/zh.json'

export const setupI18n = (app: App<Element>) => {
    const messages = {
        en,
        zh,
    }

    const i18n = createI18n({
        legacy: false, // 使用 Composition API 模式
        locale: 'en', // 默认语言
        messages,
    })

    // @ts-ignore
    app.use(i18n)
}
