import {
  reactive,
  ref,
} from 'vue';

import { defineStore } from 'pinia';

export const useStore = defineStore('main', () => {
    // 抽屉表格显示
    const isDrawerTable = ref<boolean>(false)

    const isEditDisplay = ref<boolean>(true)

    //编辑对话框
    const isDialogShow = ref<boolean>(false)

    //电池列表添加对话框
    const isAddDialog = ref<boolean>(false)
    //登录栏显示
    const isSiderbar = ref<boolean>(true)
    //当前路由
    const routerdata = ref(null)
    //侧边栏高度自适应
    const sideHight = ref()
    //编辑
    const isSetoption = ref<boolean>(false)
    //表单
    const datasetForm: any = reactive({
        mart_data_name: '',
        batch_id: 0,
        test_task_id: '',
        test_label: '',
        test_date: '',
        device:'',
        unit :'',
        battery_or_channel_number: '', //电池/通道
        charge_cut_off: 0, // 充电截止电压
        current_cut_off: 0, // 充电截至电流
        discharge_cut_off:0, // 放电截止电压
        rated_capacity: 0, // 额定容量
        manufacture: '', //制造商
        manufacture_date: '', //制造日期
        mass: null, //质量
        rated_energy: null, //额定能量
        rated_life: null, //额定寿命
        resistance: null, // 测试前内阻
        nominal_voltage: null, // 标称电压
        anode_material: '', //负极材料
        cathode_material: '', //正极材料
        separator_material: '', // 隔膜材料
        cost: null, // 成本
        desc:''//备注
    })
    //路由历史
    const historyRouterData: any = ref()
    //更新路由值
    function updateRouterData(data: any) {
        routerdata.value = data
    }

    //页面宽度变化
    const clentWidth = ref<number>(0)

    //图像宽度
    const chartWidth = ref(0)
    const isCollapseShow = ref<boolean>(false)

    return {
        isDrawerTable,
        isEditDisplay,
        routerdata,
        isSetoption,
        datasetForm,
        updateRouterData,
        isDialogShow,
        isAddDialog,
        isSiderbar,
        sideHight,
        historyRouterData,
        clentWidth,
        chartWidth,
        isCollapseShow,
    }
})
