import { createRouter, createWebHistory } from 'vue-router'

import { useStore } from '@/store'
import { useMenuStore } from '@/store/menu'
import { useUserStore } from '@/store/user'

import { getRoutes } from './routeList'

// router.afterEach((to, from, next) => {})

const initRouter = () => {
    const router = createRouter({
        history: createWebHistory(),
        routes: getRoutes(),
    })

    router.beforeEach((to, from, next) => {
        const store = useStore()

        const userStore = useUserStore()

        const menuStore = useMenuStore()

        const token = userStore.token
        store.historyRouterData = from.fullPath
        // store.updateRouterData(toPath)
        // 如果没有有效的 token，重定向到登录页面
        if ((!token || !userStore.isLogin) && to.path !== '/login') {
            if (to.path === '/resetPassword') {
                // 允许跳转到忘记密码页面
                next()
            } else {
                // 如果没有 token 且不是忘记密码页面，跳转到登录页
                next('/login')
            }
        } else {
            next()
        }
    })

    return router
}

export { initRouter }
