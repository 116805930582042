import type { RouteRecordRaw } from 'vue-router'

export const adminRoutes: RouteRecordRaw[] = [
    {
        path: 'system',
        name: 'system',
        children: [
            {
                path: 'user',
                name: 'user',
                component: () => import('@/pages/System/UserPage.vue'),
                meta: {
                    title: 'routes.system.user', // 替换为国际化 key
                    showSidebar: true,
                    level: 1,
                },
            },
        ],
    },
]

export const orderRoutes: RouteRecordRaw[] = [
    {
        path: 'order',
        name: 'order',
        component: () => import('@/pages/System/OrderPage.vue'),
        meta: {
            title: 'routes.order.order', // 替换为国际化 key
            showSidebar: true,
            level: 1,
        },
    },
    {
        path: 'vippkg',
        name: 'vippkg',
        component: () => import('@/pages/System/VipPackagePage.vue'),
        meta: {
            title: 'routes.order.vippkg', // 替换为国际化 key
            showSidebar: true,
            level: 1,
        },
    },
]

export const baseRoutes: RouteRecordRaw[] = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login/LoginPage.vue'),
        meta: {
            title: 'routes.base.login', // 替换为国际化 key
            showSidebar: false,
            level: -1,
        },
    },
]

export const commonRoutes: RouteRecordRaw[] = [
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('@/pages/Login/ResetPasswordPage.vue'),
        meta: { title: 'routes.resetPassword', showSidebar: false, level: -1 },
    },
    {
        path: '/',
        component: () => import('@/layouts/DefaultLayout.vue'),
        redirect: '/home',
        name: 'default-layout',
        meta: { title: '', level: 1 },
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/pages/Home/HomePage.vue'),
                meta: {
                    title: 'routes.home',
                    showSidebar: true,
                    level: 1,
                },
            },

            {
                path: 'personal',
                name: 'personal',
                component: () =>
                    import('@/pages/PersonalCenter/PersonalPage.vue'),
                meta: {
                    title: 'routes.personal',
                    showSidebar: true,
                    level: 1,
                },
            },
            {
                path: '/dataset',
                name: 'dataset',
                component: () => import('@/pages/Dataset/DatasetPage.vue'),
                meta: {
                    title: 'routes.dataset.dataset',
                    showSidebar: true,
                    level: 2,
                },
            },
            {
                path: '/dataset/battery',
                name: 'battery',
                component: () => import('@/pages/Dataset/Battery/index.vue'),
                meta: {
                    title: 'routes.dataset.battery',
                    showSidebar: true,
                    level: 3,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                    ],
                },
            },
            {
                path: '/dataset/battery/dataview',
                name: 'dataview',
                component: () =>
                    import(
                        '@/pages/Dataset/Battery/DatasetView/DatasetPage.vue'
                    ),
                meta: {
                    title: 'routes.dataset.dataview',
                    showSidebar: true,
                    level: 5,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                        {
                            path: '/dataset/battery',
                            title: 'routes.dataset.battery',
                        },
                    ],
                },
            },
            {
                path: '/dataset/battery/indicator',
                name: 'indicator',
                component: () =>
                    import('@/pages/Dataset/Indecator/IndecatorPage.vue'),
                meta: {
                    title: 'routes.dataset.indicator',
                    level: 5,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                        {
                            path: '/dataset/battery',
                            title: 'routes.dataset.battery',
                        },
                    ],
                },
            },
            {
                path: '/dataset/dataset-portrait',
                name: 'dataset-portrait',
                component: () =>
                    import(
                        '@/pages/Dataset/DatasetPortrait/DatasetPortraitPage.vue'
                    ),
                meta: {
                    title: 'routes.dataset.dataset-portrait',
                    level: 3,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                    ],
                },
            },
            //上传
            {
                path: 'upload',
                name: 'upload',
                component: () => import('@/pages/Upload/UploadPage.vue'),
                meta: {
                    title: 'routes.upload',
                    level: 1,
                },
            },
            //routes.analysis.morebat.morebattery_chart
            {
                path: '/analysis/morebat',
                name: 'morebat',
                redirect: '/analysis/morebat/morebat_step',
                meta: {
                    title: 'routes.analysis.morebat.morebat_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'morebat_dataset',
                        name: 'morebat_dataset',
                        component: () =>
                            import('@/pages/Analysis/MoreBat/MoreBatPage.vue'),
                        meta: {
                            title: 'routes.analysis.morebat.morebat_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'morebat_step',
                        name: 'morebat_step',
                        component: () =>
                            import(
                                '@/pages/Analysis/MoreBat/MoreStepbatPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.morebat.morebat_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'morebattery_chart',
                        name: 'morebattery_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/MoreBat/MoreBatteryPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.morebat.morebattery_chart',
                            showSidebar: true,
                            level: 5,
                            breadcrumbs: [
                                {
                                    path: '/analysis/morebat',
                                    title: 'routes.analysis.morebat.morebattery_chart',
                                },
                            ],
                        },
                    },
                ],
            },
            // routes.analysis.consistency.consistency_dataset
            {
                path: '/analysis/consistency',
                name: 'consistency',
                redirect: '/analysis/consistency/consistency_dataset',
                meta: {
                    title: 'routes.analysis.consistency.consistency_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'consistency_dataset',
                        name: 'consistency_dataset',
                        component: () =>
                            import(
                                '@/pages/Analysis/Consistency/ConsistencySetPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.consistency.consistency_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'consistency_chart',
                        name: 'consistency_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/Consistency/ConsistencyPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.consistency.consistency_chart',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/consistency',
                                    title: 'routes.analysis.consistency.consistency_dataset',
                                },
                            ],
                        },
                    },
                ],
            },
            // routes.analysis.relevance.relevance_dataset
            {
                path: '/analysis/relevance',
                name: 'relevance',
                redirect: '/analysis/relevance/relevance_dataset',
                meta: {
                    title: 'routes.analysis.relevance.relevance_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'relevance_dataset',
                        name: 'relevance_dataset',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceSelectPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'relevance_soh',
                        name: 'relevance_soh',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceWork/RelevanceSohWork.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_soh',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/relevance',
                                    title: 'routes.analysis.relevance.relevance_dataset',
                                },
                            ],
                        },
                    },
                    {
                        path: 'relevance_target',
                        name: 'relevance_target',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceWork/RelevanceTarget.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_target',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/relevance',
                                    title: 'routes.analysis.relevance.relevance_dataset',
                                },
                            ],
                        },
                    },
                ],
            },
            // routes.analysis.lifefactor.life_select
            {
                path: '/analysis/lifefactor',
                name: 'lifefactor',
                redirect: '/analysis/lifefactor/life_select',
                meta: {
                    title: '相关性分析',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'life_select',
                        name: 'life_select',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/LifeSelectPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_select',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'life_chart',
                        name: 'life_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/LifeFactorPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_chart',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/lifefactor/life_select',
                                    title: 'routes.analysis.lifefactor.life_select',
                                },
                            ],
                        },
                    },
                    {
                        path: 'life_inference',
                        name: 'life_inference',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/InferenceDatasetPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_chart',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/lifefactor/life_select',

                                    title: 'routes.analysis.lifefactor.life_select',
                                },
                            ],
                        },
                    },
                ],
            },
            // 健康状态分析
            {
                path: '/analysis/sohtraining',
                name: 'sohtraining',
                redirect: '/analysis/sohtraining/life_sohtraining',
                meta: {
                    title: '健康状态分析',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'life_sohtraining',
                        name: 'life_sohtraining',
                        component: () =>
                            import(
                                '@/pages/Analysis/SohPage/SohTrainingSelectPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.sohtraining.life_sohtraining',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'soh_modelTrain',
                        name: 'soh_modelTrain',
                        component: () =>
                            import(
                                '@/pages/Analysis/SohPage/SohChart/SohOnlineEchart.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.sohtraining.soh_modelTrain',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/sohtraining',
                                    title: 'routes.analysis.sohtraining.life_sohtraining',
                                },
                            ],
                        },
                    },
                ],
            },

            //用户手册
            {
                path: 'userhelp',
                name: 'userhelp',
                component: () =>
                    import('@/pages/PersonalCenter/UserHelp/UserhelpPage.vue'),
                meta: {
                    title: 'routes.userhelp',
                    level: 1,
                },
            },
            {
                path: '/pay',
                name: 'pay',
                component: () => import('@/pages/System/Pay/index.vue'),
                meta: {
                    title: 'routes.pay',
                    level: 1,
                },
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: () => import('@/pages/NotFoundPage.vue'),
            },
        ],
    },
]

export const getRoutes = () => {
    // let routes: RouteRecordRaw[] = [
    //     // ...orderRoutes,
    //     ...baseRoutes,
    //     ...commonRoutes,
    // ]
    let routes: RouteRecordRaw[] = [
        // ...orderRoutes,
        ...routerList,
    ]
    return routes
}

const routerList: RouteRecordRaw[] = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login/LoginPage.vue'),
        meta: { title: '登录页', showSidebar: false, level: -1 },
    },

    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('@/pages/Login/ResetPasswordPage.vue'),
        meta: { title: 'routes.resetPassword', showSidebar: false, level: -1 },
    },
    {
        path: '/',
        component: () => import('@/layouts/DefaultLayout.vue'),
        redirect: '/home',
        meta: { title: '', level: 1 },
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/pages/Home/HomePage.vue'),
                meta: {
                    title: 'routes.home',
                    showSidebar: true,
                    level: 1,
                },
            },
            {
                path: 'system',
                name: 'system',
                children: [
                    {
                        path: 'user',
                        name: 'user',
                        component: () => import('@/pages/System/UserPage.vue'),
                        meta: {
                            title: 'routes.system.user',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'order',
                        name: 'order',
                        component: () => import('@/pages/System/OrderPage.vue'),
                        meta: {
                            title: '订单管理',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'order_admin',
                        name: 'order_admin',
                        component: () =>
                            import('@/pages/System/AllOrderPage.vue'),
                        meta: {
                            title: '所有订单',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'vippkg',
                        name: 'vippkg',
                        component: () =>
                            import('@/pages/System/VipPackagePage.vue'),
                        meta: {
                            title: '套餐管理',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'pay',
                        name: 'payPage',
                        component: () => import('@/pages/System/BuyPage.vue'),
                        meta: {
                            title: '购买套餐',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'order_success',
                        name: 'OrderSuccess',
                        component: () =>
                            import('@/pages/System/OrderSuccessPage.vue'),
                        meta: {
                            title: '购买套餐',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                ],
            },
            {
                path: 'personal',
                name: 'personal',
                component: () =>
                    import('@/pages/PersonalCenter/PersonalPage.vue'),
                meta: {
                    title: 'routes.personal',
                    showSidebar: true,
                    level: 1,
                },
            },
            {
                path: 'dataset',
                name: 'dataset',
                component: () => import('@/pages/Dataset/DatasetPage.vue'),
                meta: {
                    title: 'routes.dataset.dataset',
                    showSidebar: true,
                    level: 2,
                },
            },
            {
                path: 'dataset/battery',
                name: 'battery',
                component: () => import('@/pages/Dataset/Battery/index.vue'),
                meta: {
                    title: 'routes.dataset.battery',
                    showSidebar: true,
                    level: 4,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                    ],
                },
            },
            {
                path: 'dataset/battery/dataview',
                name: 'dataview',
                component: () =>
                    import(
                        '@/pages/Dataset/Battery/DatasetView/DatasetPage.vue'
                    ),
                meta: {
                    title: 'routes.dataset.dataview',
                    showSidebar: true,
                    level: 5,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                        {
                            path: '/dataset/battery',
                            title: 'routes.dataset.battery',
                        },
                    ],
                },
            },

            {
                path: 'dataset/battery/indicator',
                component: () =>
                    import('@/pages/Dataset/Indecator/IndecatorPage.vue'),
                meta: {
                    title: 'routes.dataset.indicator',
                    level: 5,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                        {
                            path: '/dataset/battery',
                            title: 'routes.dataset.battery',
                        },
                    ],
                },
            },
            {
                path: 'dataset/dataset-portrait',
                component: () =>
                    import(
                        '@/pages/Dataset/DatasetPortrait/DatasetPortraitPage.vue'
                    ),
                meta: {
                    title: 'routes.dataset.dataset-portrait',
                    level: 5,
                    breadcrumbs: [
                        {
                            path: '/dataset',
                            title: 'routes.dataset.dataset',
                        },
                        {
                            path: '/dataset/battery',
                            title: 'routes.dataset.battery',
                        },
                    ],
                },
            },
            //上传
            {
                path: 'upload',
                name: 'upload',
                component: () => import('@/pages/Upload/UploadPage.vue'),
                meta: {
                    title: 'routes.upload',
                    level: 1,
                },
            },
            //routes.analysis.morebat.morebattery_chart
            {
                path: '/analysis/morebat',
                name: 'morebat',
                redirect: '/analysis/morebat/morebat_step',
                meta: {
                    title: 'routes.analysis.morebat.morebat_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'morebat_dataset',
                        name: 'morebat_dataset',
                        component: () =>
                            import('@/pages/Analysis/MoreBat/MoreBatPage.vue'),
                        meta: {
                            title: 'routes.analysis.morebat.morebat_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'morebat_step',
                        name: 'morebat_step',
                        component: () =>
                            import(
                                '@/pages/Analysis/MoreBat/MoreStepbatPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.morebat.morebat_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },

                    {
                        path: 'morebattery_chart',
                        name: 'morebattery_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/MoreBat/MoreBatteryPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.morebat.morebattery_chart',
                            showSidebar: true,
                            level: 5,
                            breadcrumbs: [
                                {
                                    path: '/analysis/morebat',
                                    title: 'routes.analysis.morebat.morebattery_chart',
                                },
                            ],
                        },
                    },
                ],
            },
            // routes.analysis.consistency.consistency_dataset
            {
                path: '/analysis/consistency',
                name: 'consistency',
                redirect: '/analysis/consistency/consistency_dataset',
                meta: {
                    title: 'routes.analysis.consistency.consistency_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'consistency_dataset',
                        name: 'consistency_dataset',
                        component: () =>
                            import(
                                '@/pages/Analysis/Consistency/ConsistencySetPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.consistency.consistency_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'consistency_chart',
                        name: 'consistency_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/Consistency/ConsistencyPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.consistency.consistency_chart',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/consistency',
                                    title: 'routes.analysis.consistency.consistency_dataset',
                                },
                            ],
                        },
                    },
                ],
            },
            //routes.analysis.relevance.relevance_dataset
            {
                path: '/analysis/relevance',
                name: 'relevance',
                redirect: '/analysis/relevance/relevance_dataset',
                meta: {
                    title: 'routes.analysis.relevance.relevance_dataset',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'relevance_dataset',
                        name: 'relevance_dataset',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceSelectPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_dataset',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'relevance_soh',
                        name: 'relevance_soh',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceWork/RelevanceSohWork.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_soh',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/relevance',
                                    title: 'routes.analysis.relevance.relevance_dataset',
                                },
                            ],
                        },
                    },

                    {
                        path: 'relevance_target',
                        name: 'relevance_target',
                        component: () =>
                            import(
                                '@/pages/Analysis/Relevance/RelevanceWork/RelevanceTarget.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.relevance.relevance_target',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/relevance',
                                    title: 'routes.analysis.relevance.relevance_dataset',
                                },
                            ],
                        },
                    },
                ],
            },
            //routes.analysis.lifefactor.life_select
            {
                path: '/analysis/lifefactor',
                name: 'lifefactor',
                redirect: '/lifefactor/life_select',
                meta: {
                    title: '相关性分析',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'life_select',
                        name: 'life_select',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/LifeSelectPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_select',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'life_chart',
                        name: 'life_chart',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/LifeFactorPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_chart',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/lifefactor/life_select',
                                    title: 'routes.analysis.lifefactor.life_select',
                                },
                            ],
                        },
                    },
                    {
                        path: 'life_inference',
                        name: 'life_inference',
                        component: () =>
                            import(
                                '@/pages/Analysis/LifeFactor/InferenceDatasetPage.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.lifefactor.life_chart',
                            showSidebar: true,
                            level: 3,
                            breadcrumbs: [
                                {
                                    path: '/analysis/lifefactor/life_select',

                                    title: 'routes.analysis.lifefactor.life_select',
                                },
                            ],
                        },
                    },
                ],
            },

            //soh
            {
                path: '/analysis/sohtraining',
                name: 'sohtraining',
                redirect: '/analysis/sohtraining/life_sohtraining',
                meta: {
                    title: '健康状态分析',
                    showSidebar: true,
                    level: 1,
                },
                children: [
                    {
                        path: 'life_sohtraining',
                        name: 'life_sohtraining',
                        component: () =>
                            import(
                                '@/pages/Analysis/SohPage/SohTrainingSelectPage.vue'
                            ),
                        meta: {
                            title: '健康状态分析',
                            showSidebar: true,
                            level: 1,
                        },
                    },
                    {
                        path: 'soh_modelTrain',
                        name: 'soh_modelTrain',
                        component: () =>
                            import(
                                '@/pages/Analysis/SohPage/SohChart/SohOnlineEchart.vue'
                            ),
                        meta: {
                            title: 'routes.analysis.sohtraining.soh_modelTrain',
                            showSidebar: true,
                            level: 2,
                            breadcrumbs: [
                                {
                                    path: '/analysis/sohtraining',
                                    title: 'routes.analysis.sohtraining.life_sohtraining',
                                },
                            ],
                        },
                    },
                    // life-select
                ],
            },

            //用户手册
            {
                path: 'userhelp',
                name: 'userhelp',
                component: () =>
                    import('@/pages/PersonalCenter/UserHelp/UserhelpPage.vue'),
                meta: {
                    title: 'routes.userhelp',
                    level: 1,
                },
            },
            {
                path: '/pay',
                name: 'pay',
                component: () => import('@/pages/System/Pay/index.vue'),
                meta: {
                    title: 'routes.pay',
                    level: 1,
                },
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: () => import('@/pages/NotFoundPage.vue'),
            },
        ],
    },
]

// function getAllPaths(nodes: RouteRecordRaw[], path: string = ''): string[] {
//     const paths: string[] = []
//     nodes.forEach((node) => {
//         const currentPath = `${path}/${node.path}`
//         paths.push(currentPath)
//         if (node.children) {
//             const childPaths = getAllPaths(node.children, currentPath)
//             paths.push(...childPaths)
//         }
//     })
//     return paths
// }
// const allRoutePath = getAllPaths(routerList)
// type ExtractPaths<T> = T extends { path: string }
//   ? T['path']
//   : T extends object
//   ? {
//       [K in keyof T]: ExtractPaths<T[K]>;
//     }[keyof T]
//   : never;
// type AllPaths = ExtractPaths<typeof routerList>
// export type {
//     AllPaths
// }

export { routerList }
