import { App } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'

function setupElementPlus(app: App<Element>) {
    app.use(ElementPlus, {
        locale: zhCn,
    })
}

export default setupElementPlus
